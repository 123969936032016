import React from "react";
import { graphql } from "gatsby";
import { Container, H2, H4, TextBody1, Button, LinkNoStyles } from "../ui";
import Layout from "../components/layout";
import InterviewsCardsGrid from "../components/InterviewsCardsGrid";
import { Box, Flex } from "rebass";

export default class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: allPosts } = data.allMarkdownRemark;
    const posts = allPosts.slice(0, 3);

    return (
      <Layout location="404">
        <Container>
          <Box my={[6, 7]}>
            <H2>
              Página não encontrada{" "}
              <span role="img" alt="sad emoji" aria-label="sad emoji">
                😔
              </span>
            </H2>
            <TextBody1>
              A página que procuras deve ter sido mudada, apagada ou, então,
              nunca existiu.
            </TextBody1>
          </Box>
          <Box my={[4, 5]}>
            <Box mb={[2, 4]}>
              <H4>
                Últimas entrevistas{" "}
                <span
                  role="img"
                  alt="point down emoji"
                  aria-label="point down emoji"
                >
                  👇
                </span>
              </H4>
            </Box>
            <InterviewsCardsGrid posts={posts} />
            <Flex justifyContent="center" my={5}>
              <LinkNoStyles to="/entrevistas">
                <Button secondary="true">Ver mais entrevistas</Button>
              </LinkNoStyles>
            </Flex>
          </Box>
        </Container>
      </Layout>
    );
  }
}

export const pageQuery = graphql`{
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {templateKey: {eq: "interview-post"}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 180)
        id
        fields {
          slug
        }
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          templateKey
          description
          color
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
}
`;
